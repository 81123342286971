<template>
  <div>
    <div class="topImg"><img alt="" src="../../assets/Rectangle 50.png" /></div>
    <div class="leftImg"><img alt="" src="../../assets/Ellipse 18.png" /></div>
    <div class="banner">
      <img alt="" src="../../assets/Snipaste_2022-01-20_20-04-05.png" />
    </div>
    <div class="title">
      OpenC is the most comprehensive on/off ramps aggregator.
    </div>
    <ul class="ul-list">
      <li>Global Wide</li>
      <li>More Competitive</li>
      <li>One-Stop Shop</li>
      <li>All-Round</li>
      <li>Multi-Dimension</li>
      <li>Multi-Dimension</li>
    </ul>
    <div class="desc">
      Committed to providing safe, fast and convenient services for corporate
      users, we carefully select the best fiat gateways around the world, making
      sure users can get access to cryptocurrencies in a relatively lower price,
      meanwhile, enjoy deep liquidity
    </div>
    <div class="infoBox flex-x flex">
      <div class="infoBox-info">
        <div class="infoBox-info-top">150,000</div>
        <div class="infoBox-info-btm">Users</div>
      </div>
      <div class="infoBox-info">
        <div class="infoBox-info-top">20%</div>
        <div class="infoBox-info-btm">Growth</div>
      </div>
      <div class="infoBox-info">
        <div class="infoBox-info-top">289,525</div>
        <div class="infoBox-info-btm">Transactions</div>
      </div>
      <div class="infoBox-info">
        <div class="infoBox-info-top">$179,943,224.24</div>
        <div class="infoBox-info-btm">Total Volume</div>
      </div>
      <!--      <div class="infoBox-info">-->
      <!--        <div class="infoBox-info-top">50+</div>-->
      <!--        <div class="infoBox-info-btm">Assets supported</div>-->
      <!--      </div>-->
    </div>
    <div class="cardBox flex-x">
      <div class="cardBox-info">
        <div class="cardBox-info-top">Global Wide</div>
        <div class="cardBox-info-btm">
          Users can enjoy on/off ramps worldwide by integrating with us or
          directly connecting to our API, saving your time and trouble of
          development
        </div>
      </div>
      <div class="cardBox-info">
        <div class="cardBox-info-top">Completely Compliant</div>
        <div class="cardBox-info-btm">
          All gateways we integrate are in strict compliance with local rules
          and regulations, making sure the security of users
        </div>
      </div>
      <div class="cardBox-info">
        <div class="cardBox-info-top">Easy to Customize</div>
        <div class="cardBox-info-btm">
          Users can have special customization according to their demands,
          including gateways preference, localization choice and design
        </div>
      </div>
    </div>
    <div class="descOne">
      <div class="descOne-content">
        Currently we have enabled more than 5 million users to buy digital
        assets with OpenC services
      </div>
    </div>
    <div class="descTwo">
      <div class="descTwo-content">
        So far, OpenC has partnered with many professional platforms, including
        LBank, TokenPocket, CoinRF, TradingUsdt and BAQRON
      </div>
    </div>
    <div class="swiperBox">
      <!--      <el-carousel-->
      <!--          ref="swiperRef"-->
      <!--          :autoplay="true"-->
      <!--          :initial-index="currentIndex"-->
      <!--          arrow="never"-->
      <!--          height="200px"-->
      <!--      >-->
      <!--        <el-carousel-item>-->
      <div class="flex-x" v-show="currentIndex == 0">
        <div class="swiperBox-left">
          <div class="swiperBox-title">CoinRF</div>
          <div class="swiperBox-desc">
            Built on customer-centric values, CoinRF is committed to building a
            professional, smart and efficient trading and broker platform.
            CoinRF aims to bring revolution to the industry by providing users
            with the fastest and fairest trading experiences.
          </div>
        </div>
      </div>
      <!--        </el-carousel-item>-->
      <!--        <el-carousel-item>-->
      <div class="flex-x" v-show="currentIndex == 1">
        <div class="swiperBox-left">
          <div class="swiperBox-title">LBank</div>
          <div class="swiperBox-desc">
            LBank Exchange,which full name is Ledger Bank Exchange,is a safe and
            professional digital assets trading platform. As one of the world's
            leading exchanges,LBank serves over 4.5 million registered users
            with a daily trading volume of 1.5 billion USD.
          </div>
        </div>
      </div>
      <!--        </el-carousel-item>-->
      <!--        <el-carousel-item>-->
      <div class="flex-x" v-show="currentIndex == 2">
        <div class="swiperBox-left">
          <div class="swiperBox-title">TokenPocket</div>
          <div class="swiperBox-desc">
            TokenPocket is the world’s leading multi-chain self-custodial wallet,
             which supports mainstream public chains, which has provided reliable services for over 20 million users around the world.
          </div>
        </div>
      </div>
      <!--        </el-carousel-item>-->
      <!--        <el-carousel-item>-->
      <div class="flex-x" v-show="currentIndex == 3">
        <div class="swiperBox-left">
          <div class="swiperBox-title">TradingUsdt.com</div>
          <div class="swiperBox-desc">
            TradingUsdt is a comprehensive P2P crypto-products trading broker
            platform. As a new cryptocurrency platform with over 1 million
            registered users, TradingUsdt has grow into one of the fastest
            growing platform.
          </div>
        </div>
      </div>
      <!--        </el-carousel-item>-->
      <!--        <el-carousel-item>-->
      <div class="flex-x" v-show="currentIndex == 4">
        <div class="swiperBox-left">
          <div class="swiperBox-title">BAQRON</div>
          <div class="swiperBox-desc">
            BAQRON is the first digital assets exchanges with a copy trading
            system Partnered with Binance, BAQRON can avoid the problem of
            slippage even if the market price fluctuates. As a comprehensive
            platform, BAQRON also provide self-developed analysis tools for all
            users.
          </div>
        </div>
      </div>
      <!--        </el-carousel-item>-->
      <!--      </el-carousel>-->
    </div>
    <div class="arrow swiperBox swiperBox1">
      <div class="leftArrow" @click="currentIndexPrev()">
        <img alt="" src="../../assets/Group 69.png" />
      </div>
      <el-carousel
        class="swiperBox-box"
        ref="swiperRef"
        :autoplay="true"
        :initial-index="currentIndex"
        arrow="never"
        type="card"
        indicator-position="none"
        @change="currentIndexchange"
      >
        <el-carousel-item>
          <div class="flex-x">
            <div class="swiperBox-img">
              <img alt="" src="../../assets/WechatIMG1994.png" />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="flex-x">
            <div class="swiperBox-img">
              <img alt="" src="../../assets/WechatIMG1993.png" />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="flex-x">
            <div class="swiperBox-img">
              <img alt="" src="../../assets/TokenPocket_Logo__ traverse.png" />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="flex-x">
            <div class="swiperBox-img">
              <img alt="" src="../../assets/WechatIMG1990.png" />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="flex-x">
            <div class="swiperBox-img">
              <img alt="" src="../../assets/WechatIMG1991.jpeg" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="rightArrow leftArrow" @click="currentIndexNext()">
        <img alt="" src="../../assets/Group 141.png" />
      </div>
    </div>
    <div class="process">
      <div class="process-title">Growth process</div>
      <div class="process-img phoneImg1">
        <img alt="" src="../../assets/Group 135.png" />
      </div>
      <div class="process-img phoneImg">
        <img alt="" src="../../assets/Group 232.svg" />
      </div>
    </div>
    <div class="gateway">
      <div class="gateway-title">payment service provider</div>
      <div ref="mySwiperRef1">
        <div class="gateway-flex flex-x flex-btn swiper-wrapper gatewaybox_box">
          <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/image 56.png" />
            </div>
            <div class="gateway-desc">Banxa</div>
          </div>
          <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/image 58.png" />
            </div>
            <div class="gateway-desc">Mercuryo</div>
          </div>
          <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/image 59.png" />
            </div>
            <div class="gateway-desc">Moonpay</div>
          </div>
          <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/image 60.png" />
            </div>
            <div class="gateway-desc">Simplex</div>
          </div>
        <!--  <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/image 61.png" />
            </div>
            <div class="gateway-desc">XanPool</div>
          </div> -->
          <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/Snipaste_2022-01-22_23-15-57.png" />
            </div>
            <div class="gateway-desc">Coinify</div>
          </div>
         <!-- <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/Snipaste_2022-01-22_23-16-43.png" />
            </div>
            <div class="gateway-desc">Paxful</div>
          </div> -->
		  <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
		    <div class="gateway-img">
		      <img alt="" src="../../assets/alchemy-pay.png" />
		    </div>
		    <div class="gateway-desc">alchemy pay</div>
		  </div>
		  <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
		    <div class="gateway-img">
		      <img alt="" src="../../assets/straitsx.png" />
		    </div>
		    <div class="gateway-desc">straitsx</div>
		  </div>
		  <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
		    <div class="gateway-img">
		      <img alt="" src="../../assets/legendtrading.png" />
		    </div>
		    <div class="gateway-desc">legend trading</div>
		  </div>
		  <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
		    <div class="gateway-img">
		      <img alt="" src="../../assets/inst.png" />
		    </div>
		    <div class="gateway-desc">inst</div>
		  </div>
		  <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
		    <div class="gateway-img">
		      <img alt="" src="../../assets/geopay.png" />
		    </div>
		    <div class="gateway-desc">geo pay</div>
		  </div>
		  <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
		    <div class="gateway-img">
		      <img alt="" src="../../assets/paybis.png" />
		    </div>
		    <div class="gateway-desc">paybis</div>
		  </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  name: "index",
  data() {
    return {
      currentIndex: 0,
    };
  },
  created() {},
  mounted() {
    new Swiper(this.$refs.mySwiperRef1, {
      autoplay: {
        delay: 0, //自动切换的时间间隔，单位ms
      },
      speed: 4000,
      loop: true,
      slidesPerView: 3,
      spaceBetween: 30,
    });
  },
  methods: {
    currentIndexPrev() {
      // this.currentIndex = this.currentIndex + val
      // console.log(this.currentIndex)
      // this.$refs.swiperRef.prev();
      this.$refs.swiperRef.prev();
    },
    currentIndexNext() {
      // this.$refs.swiperRef.next();
      this.$refs.swiperRef.next();
    },
    currentIndexchange(val) {
      this.currentIndex = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/public.scss";
.swiper-wrapper {
  transition-timing-function: linear !important; //想好具体位置放到哪，得能替换！
}
.topImg {
  width: 100%;
  height: 720px;
  position: absolute;
  top: 0;
  left: 0;
}

.banner {
  width: 1136px;
  height: 570px;
  border-radius: 30px;
  margin: 0 auto 0;
  padding-top: 178px;
}

.title {
  width: 935px;
  font-size: 48px;
  text-align: center;
  margin: 80px auto 0;
}

.ul-list {
  width: 1136px;
  margin: 50px auto 80px;
  font-size: 16px;

  li {
    float: left;
    margin-left: 45px;
    padding-left: 35px;
  }

  li:nth-of-type(1) {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
}

.desc {
  padding-top: 80px;
  width: 1136px;
  font-size: 24px;
  opacity: 0.6;
  margin: 0 auto;
  font-family: "fontFace1" !important;
}

.infoBox {
  width: 1136px;
  height: 222px;
  padding: 0 90px;
  background: #3fbac2;
  border-radius: 32px;
  margin: 50px auto;
  justify-content: center;
  padding-top: 60px;
  box-sizing: border-box;

  &-info {
    width: 185px;
    margin-left: 30px;
    color: #fff;

    &-top {
      font-size: 40px;
    }

    &-btm {
      font-size: 24px;
      padding-top: 16px;
      box-sizing: border-box;
      font-family: "fontFace1" !important;
    }
  }

  &-info:nth-of-type(1) {
    margin-left: 0;
  }
}

.cardBox {
  width: 1136px;
  border-radius: 32px;
  margin: 80px auto;
  justify-content: space-between;
  padding-top: 80px;
  box-sizing: border-box;

  &-info {
    width: 277px;
    margin-left: 88px;
    color: #000;

    &-top {
      font-size: 24px;
    }

    &-btm {
      padding-top: 16px;
      box-sizing: border-box;
      font-size: 18px;
      font-family: "fontFace1" !important;
      color: #000000;
      opacity: 0.6;
    }
  }

  &-info:nth-of-type(1) {
    margin-left: 0;
  }
}
.swiperBox1 {
  padding-top: 30px !important;
}
.swiperBox {
  width: 1136px;
  margin: 0 auto;
  padding-top: 100px;
  box-sizing: border-box;
  &-box {
    width: 700px;
    margin: 0 auto;
  }
  ::v-deep .el-carousel__container {
    height: 124px;
  }
  ::v-deep .el-carousel__item--card.is-active {
    //margin: 0 50px;
  }
  &-img {
    //width: 380px;
    //margin-left: 130px;
    width: 80%;
    height: 80%;
    margin: 0 auto;
  }

  &-left {
    //width: 576px;
    text-align: center;
  }

  &-title {
    font-size: 24px;
    color: #000000;
  }

  &-desc {
    font-family: "fontFace1" !important;
    font-size: 18px;
    color: #000000;
    opacity: 0.6;
    padding-top: 32px;
    box-sizing: border-box;
  }
  ::v-deep .el-carousel__item--card {
    width: 33.33%;
    height: 124px;
    background: #ffffff;
    //box-shadow: 0px 0px 118px #F0F0F0;
    border-radius: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 7.5%;
  }
}

.arrow {
  width: 1136px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .leftArrow {
    width: 45px;
    cursor: pointer;
    height: 23px;
  }

  .rightArrow {
    width: 45px;
    height: 23px;
    //margin-left: 130px;
  }
}

.descOne {
  width: 1136px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 120%;
  text-align: space-between;
  color: #000000;
  opacity: 0.6;
  padding-top: 80px;
  box-sizing: border-box;
  font-family: "fontFace1" !important;

  &-content {
    width: 576px;
  }
}

.descTwo {
  width: 1136px;
  margin: 0 auto;
  padding-top: 24px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 120%;
  text-align: space-between;
  color: #000000;
  opacity: 0.6;
  font-family: "fontFace1" !important;

  &-content {
    width: 576px;
  }
}

.process {
  width: 1154px;
  margin: 0 auto;
  padding-top: 270px;
  box-sizing: border-box;

  &-title {
    font-size: 48px;
    color: #000000;
    text-align: center;
  }

  &-img {
    width: 100%;
    height: 521px;
    margin-top: 106px;
  }
}
.gatewaybox_box {
  //width: 1380px;
}
.gateway {
  width: 1136px;
  margin: 0 auto;
  padding-bottom: 260px;
  box-sizing: border-box;
  overflow: hidden;

  &-box {
    padding: 10px 20px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 118px #f0f0f0;
    border-radius: 15px;
  }

  &-title {
    font-size: 48px;
    text-align: center;
    padding-top: 260px;
    padding-bottom: 115px;
    box-sizing: border-box;
  }

  &-img {
    width: 60px;
    height: 60px;
  }

  &-desc {
    font-size: 24px;
    margin-left: 11px;
  }
}
.phoneImg {
  display: none;
}
.leftImg {
  width: 400px;
  height: 410px;
  position: absolute;
  top: 0px;
}
@media screen and (max-device-width: 768px) {
  .title {
    font-size: 80px;
    width: 80%;
  }
  .swiperBox {
    height: 1000px;
    display: flex;
    align-items: center;
  }
  .banner {
    //padding-top: 100px;
    width: 90%;
    height: 900px;
    padding-top: 400px;
  }
  .desc {
    width: 90%;
    font-size: 60px;
    padding-top: 450px;
    box-sizing: border-box;
    //margin-top: 100px;
  }
  .leftArrow {
    display: none;
  }
  .rightArrow {
    display: none;
  }
  //.ul-list:nth-of-type(1){
  //  margin-left: 88px;
  //}
  .ul-list {
    width: 100%;
    font-size: 60px;
    height: 100%;
    padding: 50px 0;
    box-sizing: border-box;
    margin-top: 100px;
    li:nth-of-type(1) {
      margin-left: 88px;
    }
    li:nth-of-type(2) {
      margin-left: 0px;
    }
    li {
      width: 45%;
      list-style: none;
      padding: 40px;
      box-sizing: border-box;
      //text-align: center;
    }
  }
  .infoBox {
    width: 90%;
    margin-top: 200px;
    flex-wrap: wrap;
    height: auto;
    padding: 100px 50px;
    &-info {
      width: 50%;
      margin-left: 0;
      margin-top: 100px;
      &-top {
        font-size: 100px;
      }
      &-btm {
        font-size: 70px;
      }
    }
    &-info:nth-of-type(1) {
      margin-top: 0;
    }
    &-info:nth-of-type(2) {
      margin-top: 0;
    }
  }
  .cardBox {
    width: 90%;
    flex-wrap: wrap;
    &-info {
      width: 100%;
      margin-left: 0;
      margin-top: 50px;
      &-top {
        font-size: 80px;
      }
      &-btm {
        font-size: 60px;
      }
    }
  }
  .descOne {
    width: 90%;
    font-size: 70px;
    &-content {
      width: 100%;
    }
  }
  .descTwo {
    width: 90%;
    font-size: 70px;
    &-content {
      width: 100%;
    }
  }
  .swiperBox {
    width: 90%;
    &-box {
      width: 100%;
      //margin-top: 100px;
      height: 500px;
    }
    ::v-deep .el-carousel__item--card {
      width: 900px;
      height: 500px;
    }
    &-title {
      font-size: 100px;
    }
    &-desc {
      font-size: 70px;
    }
  }
  .process {
    width: 90%;
    &-title {
      font-size: 90px;
    }
    .phoneImg {
      display: block;
    }
    .phoneImg1 {
      display: none;
    }
    &-img {
      width: 70%;
      height: auto;
    }
  }
  .gateway {
    width: 90%;
    &-box {
      padding: 10px 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 0px 118px #f0f0f0;
      border-radius: 15px;
      display: flex;
      justify-content: center;
    }

    &-title {
      font-size: 100px;
      text-align: center;
      padding-top: 260px;
      padding-bottom: 250px;
      box-sizing: border-box;
    }

    &-img {
      width: 60 * 2.5px;
      height: 60 * 2.5px;
    }

    &-desc {
      font-size: 70px;
      //margin-left: 11px;
    }
  }
}
</style>
